body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.button {
  padding: 10px;
  margin: 10px;
  color: white;
  background-color: rgb(27, 152, 255);
  border: none;
  border-radius: 25px;
  font-size: 1.25em;
  cursor: pointer;
}
.box {
  max-width: calc(500px - 100px);
  width: calc(100% - 125px);
  margin: 40px auto;
  text-align: center;
  border: rgb(205, 205, 205) 1px solid;
  border-radius: 25px;
  background-color: rgb(250, 250, 250);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: gray;
  padding: 25px 50px;
}
.address-input {
  max-width: calc(100% - (1em + 14px + 30px));
  width: 100%;
  font-size: 1em;
  border-radius: 25px;
  border: 1px rgb(184, 184, 184) solid;
  padding: 7px;
  display: inline;
  margin: 5px;
}
.button2 {
  width: calc(1em + 14px);
  height: calc(1em + 14px);
  color: white;
  background-color: rgb(27, 152, 255);
  border: none;
  border-radius: 25px;
  font-size: 1.25em;
  display: inline;
  text-align: center;
  line-height: calc(1em + 14px);
  cursor: pointer;
}

.table {
  display: table;
  width: calc(100%);
  border-radius: 10px;
  border: 1px solid #ccc;
  background-color: rgb(253, 253, 253);
  margin: 20px 0;
  overflow: hidden;
}
.table-row {
  display: table-row;
}
.table-row:nth-child(2n) {
  background-color: rgb(247, 247, 247);
}
.table-cell {
  display: table-cell;
  padding: 10px;
  width: 50%;
}
.metamask-badge {
  position:fixed;
  bottom: 25px;
  right: 25px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 7px;
  border-radius: 12.5px;
  background-color: rgb(79, 79, 79);
  z-index: 10;
  font-size: 1em;
  transition: transform 0.1s ease-in-out;
  cursor: pointer;
}
.metamask-badge:hover{
  transform: scale(1.01);
}
.metamask-logo {
  height: 1.4em;
  width: 1.4em;
  vertical-align: middle;
  margin: 0px 5px;
}
.metamask-text {
  display: inline-block;
  margin: 0;
  padding: 0;
  color: rgb(243, 243, 243);
  text-decoration: none;
}
.button_white {
  padding: 10px;
  margin: 10px;
  color: rgb(27, 152, 255);
  border-color: rgb(27, 152, 255);
  border-width: 2px;
  border-style: solid;
  border-radius: 25px;
  font-size: 1.25em;
  cursor: pointer;
}